import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: 225px;
  @media(min-width: 1200px) {
    margin-bottom: 24px;
  }
  @media (max-width:  1198.98px) {
  height: 205px;
  }
  @media (max-width:  991.98px) {
    height: 235px;
  }
  @media (max-width:  767.98px) {
    height: 180px;
  }
  @media (max-width:  500.98px) {
    height: 160px;
  }
  @media (max-width:  400.98px) {
    height: 120px;
  }
  img {
    position: absolute;
    max-width: 100%;
    height: auto;
  }
  h1 {
    position: absolute;
    left: 20px;
    width: 50%;
    height: 80%;
    margin: 0;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.2;
    @media(min-width: 1200px) {
      height: 100%;
    }
    @media (max-width:  991.98px) {
      height: 90%;
    }
    @media (max-width:  767.98px) {
      height: 70%;
      font-size: 28px;
    }
    @media (max-width:  575.98px) {
      font-size: 18px;
    }
  }
`

export default ({ frontmatter }) => (
  <Wrapper>
    <img src={frontmatter.header_image} alt='' />
    <h1 className='text-white'>{frontmatter.title}</h1>
  </Wrapper>
)
