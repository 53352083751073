import React from 'react'
import { isEmpty } from 'ramda'
import Layout from '../components/layout'
import Accordion from '../components/Accordion'
import { Heading } from '../components/Typography'
import ImageWithText from '../components/ImageWithText'
import { parseSlug, getFirstNodeFm } from '../utils'
import { processEdges } from '../utils/layouts'

export default ({ pageContext, location, data, children }) => {
  const [category] = parseSlug(location.pathname)

  if (!data.allMarkdownRemark || isEmpty(data.allMarkdownRemark)) return <p>No data available.</p>
  const { edges } = data.categoryMarkdowns
  const subCategoryList = processEdges(edges)
  const frontmatter = getFirstNodeFm(data)

  return (
    <Layout {...{ location }}>
      <div className='container'>
        <div className='py-3'>
          <div className='row'>
            <div className='col-lg-4'>
              <Accordion parent={category} items={subCategoryList} slug={location.pathname} />
            </div>
            <div className='col-lg-8'>
              <div className='layout-sub-category'>
                {frontmatter.header_image ? <ImageWithText {...{ frontmatter }} /> : <Heading>{frontmatter.title}</Heading>}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
