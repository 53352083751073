import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../layouts/base-with-sidebar-for-pages'
import { withAuthProvider } from '../../../lib/auth'
import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'

const Span = styled.span`
  font-family: ${Theme.fonts.Montserrat};
`

const buildLink = (me) => {
  if (!me) {
    return
  }

  const { firstName, lastName, id, primaryEmail, phoneNumber } = me

  const omniUrl = 'https://bookings.omnihotels.com/loyalty/signup?memberSignup=PgaMember'
  const pgaQueryString = `&surname=${lastName}&firstname=${firstName}&email=${primaryEmail}&pgaNumber=${id}&phoneNumberPrefix=+1&phone=${phoneNumber}`

  return `${omniUrl}${pgaQueryString}`
}

const handleClick = ({ me }) => {
  window.open(buildLink(me))
}

export const PageWrapper = (props) => {
  return (
    <Layout {...props}>
      <div className='container layout-default'>
        <p>Omni Hotels &amp; Resorts is proud to offer PGA of America Members in good standing a Complimentary Upgrade to Champion Status in Omni’s Select Guest&reg; loyalty program. Once enrolled, you can enjoy these favorite benefits:</p>
        <ul><li>Earn free nights 2x faster</li><li>Free upgrade by 1 room type level (subject to availability)</li><li>Check-in as early as 1:00pm and check-out as late as 3:00pm (subject to availability)</li></ul>
        <p>In addition to the perks you’ll enjoy at Champion Status, you will also receive 20% discount on room reservations at nearly 50 Omni Hotels &amp; Resorts as well as complimentary green fees at 28 world-class golf courses when you travel with Omni.</p>
        <h4>Up to 20% off Omni’s Best Available Rates</h4>
        <p style={{ fontWeight: 'bold' }}>How to Book:</p>
        <p>
          <ol>
            <li><a target="_blank" rel="noopener" href={buildLink(props.me)}>Create/Log into your Select Guest account</a></li>
            <li>When booking, use corporate code: <span style={{ fontWeight: 'bold' }}>CRPGLF</span></li>
          </ol>
        </p>
        <p>You must log into your Select Guest account, prior to booking, in order to access the discount. The discount is subject to availability and may not be available at all hotels for all date ranges. The discount may not be used in conjunction with other discounts or group bookings.</p>
        <h4>Complimentary Green Fees</h4>
        <p>While visiting our resorts, Omni is pleased to offer PGA Members access to complimentary green fees at courses Omni owns and operates. There may be some restrictions on tee times due to seasonality and club membership rules. Once you book a reservation, contact the golf shop at the resort to plan your round of golf. You will receive one complimentary green fee per night stayed. Additional fees may apply, for other services, carts and players. Complimentary golf is not available at the Omni Hilton Head Oceanfront Resort and Omni Scottsdale Resort & Spa at Montelucia, green fee discounts apply for stays at Omni PGA Frisco. You will need to provide your room number and proof of PGA Membership at check in.<br /></p>
        <h4>Interested in playing at PGA Frisco?</h4>
        <Span><a href='/my-membership/pga-frisco/play-at-pga-frisco/'>Click here</a> to learn more.</Span>
        <br />
       
        <p>If you are ready to join the Select Guest loyalty program, please click below:</p>
        <button onClick={() => handleClick(props)} className='btn btn-info mb-3'>Register Now</button>
        <p>If you are already an Omni Select Guest member, or have trouble signing up, please call 877-440-6664 and the Omni membership services team will update your account or assist as needed. Please provide your PGA Member number and name as it appears on your membership.</p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/perks-discounts/omni-hotels/"
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
    categoryMarkdowns: allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/member-benefits/"
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`

export default withAuthProvider(PageWrapper)

